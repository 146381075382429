import React from "react";
import CWCLogo from "../../../assets/image/cwc/Coffee with careerline2.png";
import CoffeeFair from "../../../assets/image/cwc/coffeefair1.png";
import CoffeeFair2 from "../../../assets/image/cwc/coffeefair2.png";
import Attend1 from "../../../assets/image/cwc/attend1.png";
import Attend2 from "../../../assets/image/cwc/attend2.png";
import Attend3 from "../../../assets/image/cwc/attend3.png";
// import HEROBANNER from "../../../assets/image/cwc/mobile with image3.png";
import CoffeeBean2 from "../../../assets/image/cwc/Coffee Beans 2.png";
import CWCPic from "../../../assets/image/cwc/Cafe2.png";
import CoffeeCupBanner from "../../../assets/image/cwc/coffeebanner.png";
import Women from "../../../assets/image/cwc/Woman2.png";
import RightAdvice from "../../../assets/image/cwc/Careerline tagline2.png";
import BhavinThakkar from "../../../assets/image/cwc/Mr Bhavin Thaker2.png";
import Flags from "../../../assets/image/cwc/Flags2.png";
import { FaJava } from "react-icons/fa6";
import { BsArrowUpRightCircle } from "react-icons/bs";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import CWCEventForm from "./CWCEventForm";
import { Element, Link } from "react-scroll";

const testimonials = [
  {
    name: "Priya Shah",
    text: "This event was a game changer! I received a scholarship and fee waiver for my application to a top USA university. Don’t miss it!",
  },
  {
    name: "Amit Patel",
    text: "Thank you Careerline for organizing such event that I got opportunity to meet representatives of universities & got a lot of guidance.",
  },
  {
    name: "Neel Patel",
    text: "The direct interaction with university representatives helped me understand the admission process better. Highly recommended!",
  },
];

const CWCEventPage = () => {
  return (
    <div>
      <div className="cwc-event-banner-section"></div>
      <div className="cwc-event-hero-section pt-5 ">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h1 className="text-uppercase mb-0">
                Dreaming of{" "}
                <img
                  src={Flags}
                  alt="flags"
                  style={{ width: "clamp(72px, 15vw, 400px)" }}
                />
                <br />
                studying abroad
              </h1>
              <p className="m-0 hero-subline">
                is exciting, but it can also feel overwhelming
                <br />
              </p>
              <p className="m-0 hero-line">
                You have a <i>thousand questions -</i>
              </p>
            </div>
            <div className="col-md-4 text-md-end">
              <img src={CWCLogo} alt="Coffee with Careerline" width="150px" />
            </div>
          </div>
          <div className="row mt-3 mt-md-5">
            {/* <div className="col-lg-7 d-flex align-items-end">
              <img src={HEROBANNER} alt="" width={"100%"} />
            </div> */}
            {/* <div className="col-lg-5 pb-5 d-flex justify-content-center"> */}
            <Element
              className="col-12 pb-5 d-flex justify-content-center"
              name="cwcregistration-form"
            >
              <CWCEventForm />
            </Element>
          </div>
        </div>
      </div>
      <div className="cwc-event-goodnews-section pt-5 ">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-3">
              <h1 className="mb-0">
                THE GOOD NEWS:{" "}
                <span
                  style={{
                    fontSize: "clamp(14px, 2vw, 30px)",
                    fontWeight: 300,
                    verticalAlign: "middle",
                    display: "inline-block",
                  }}
                >
                  We’ve got all the answers!
                </span>
              </h1>
            </div>
            <div className="col-lg-7">
              <p className="goodnews-subline">
                Join us for a{" "}
                <b style={{ color: "#FFE145" }}>casual coffee session</b> where
                we answer your questions and{" "}
                <i style={{ color: "#FFE145" }}>guide you step-by-step</i>. And
                did we mention the coffee is on us!
              </p>
              <table border={0} className="w-100 w-lg-75">
                <tr>
                  <td width={"30%"}>Date &nbsp;&nbsp;&nbsp; :</td>
                  <td>08/02/2025</td>
                </tr>
                <tr>
                  <td>Time &nbsp;&nbsp;&nbsp;:</td>
                  <td>10:00 AM to 01:00 PM</td>
                </tr>
                <tr>
                  <td>Venue &nbsp;:</td>
                  <td>
                    <a
                      href="https://maps.app.goo.gl/YS56iHYubNsfQ5P68?g_st=ic"
                      target="_blank"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      {/* Shambhu’s Cafe{" "}
                      <FaJava style={{ verticalAlign: "baseline" }} /> */}
                      Shambhu's Cafe, A - 307, Navratna Corporate Park Iscon,
                      Ambli Rd, Ashok Vatika, Ahmedabad, Gujarat 380058
                    </a>
                  </td>
                </tr>
              </table>
              <div style={{ padding: "50px 0" }}>
                <Link
                  activeClass="active"
                  to="cwcregistration-form"
                  offset={-120}
                  duration={300}
                  className="event-banner-btn"
                >
                  Reserve My Free Spot & Coffee Now!
                </Link>
              </div>
            </div>
            <div className="col-lg-5">
              <img src={CWCPic} alt="Coffee with Careerline" height="100%" />
            </div>
          </div>
        </div>
      </div>
      <div className="cwc-event-clgfair-section p-0 pt-5">
        <div className="container">
          <div className="row">
            <div className="col-9">
              <h1 className="text-uppercase mb-0">
                <span style={{ fontWeight: 300 }}>This Isn't Your </span>Average
                College Fair…
              </h1>
              <p className="m-0 mt-3">
                You’ve heard of college fairs, right? Tables, pamphlets, and
                people in suits who give you 20-minute speeches you don’t
                remember? Yeah, this is not that.
                <br />
              </p>
            </div>
            <div className="col-3 d-sm-flex">
              <div className="college-fair-sec1">
                <img
                  src={CoffeeFair}
                  alt="Coffee with Careerline"
                  width="100%"
                />
              </div>
              <div className="w-100">
                <img
                  src={CoffeeFair2}
                  alt="Coffee with Careerline"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="p-0 mt-md-5 pb-md-3 pb-lg-5 text-center">
          <img src={CoffeeCupBanner} alt="coffee cup" />
        </div>
      </div>
      <div className="cwc-event-section-5 pt-5 mb-0 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 text-center">
              <img src={Women} alt="coffee with careerline" width={"80%"} />
            </div>
            <div className="col-lg-6 mt-5 mt-lg-0 d-flex flex-column justify-content-center position-relative">
              <img
                src={CoffeeBean2}
                alt="Coffee With Careerline"
                className="coffee-bean"
              />
              <p className="m-0 hero-subline mb-3 mb-lg-5">
                Think of it as the{" "}
                <b>coolest (and most caffeinated) study- abroad chat </b> you’ll
                ever have!
                <br />
              </p>
              <p className="m-0 hero-line mb-3 mb-lg-5">
                You’ll leave with a head full of answers, not just brochures.
              </p>
              <Link
                activeClass="active"
                to="cwcregistration-form"
                offset={-120}
                duration={300}
                className="event-banner-btn"
              >
                <div className="section-4-btn mb-5 mb-lg-0">
                  So, <b>reserve your seat</b>, <br /> your <i>coffee</i> is on
                  us!
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="cwc-event-section-6 pt-5 ">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-3">
              <h1 className="mb-0">Reasons to Attend</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 text-center text-md-start">
              <img
                src={Attend1}
                alt="Coffee With Careerline"
                className="mb-4"
              />
              <img src={Attend2} alt="Coffee With Careerline" />
            </div>
            <div className="col-md-6 d-flex align-items-end p-0">
              <img src={Attend3} alt="Coffee With Careerline" />
            </div>
          </div>
        </div>
      </div>
      <div
        className="cwc-event-section-7 pt-5 mb-0"
        // style={{ paddingBottom: "100px" }}
      >
        <div className="container">
          <div className="row mb-4 mb-lg-5">
            <div className="col-8">
              <h1 className="text-uppercase mb-0">Things To Bring</h1>
            </div>
            <div className="col-4 text-end">
              <img
                src={RightAdvice}
                alt="Right Advice"
                className="right-advice"
              />
            </div>
          </div>
          <div className="row">
            <div
              className="col-12 pb-lg-3"
              style={{ borderBottom: "1px solid #fff" }}
            >
              <h2>
                Yourself <BsArrowUpRightCircle style={{ color: "white" }} />
              </h2>
              <p>
                And maybe a friend because good conversations are even better
                when shared.
              </p>
            </div>
            <div
              className="col-12 mt-4 pb-lg-3"
              style={{ borderBottom: "1px solid #fff" }}
            >
              <h2>
                Your Curiosity{" "}
                <BsArrowUpRightCircle style={{ color: "white" }} />
              </h2>
              <p>
                Bring that excitement – we’ll show you how to turn it into
                reality.
              </p>
            </div>
            <div
              className="col-12 mt-4 pb-lg-3"
              style={{ borderBottom: "1px solid #fff" }}
            >
              <h2>
                Your Questions{" "}
                <BsArrowUpRightCircle style={{ color: "white" }} />
              </h2>
              <p>
                Got doubts? Confusion? Random thoughts at 3 AM about
                scholarships? Bring ‘em all. We’ve got answers!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="cwc-event-section-8 pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-4 mb-lg-5">
              <h1 className="text-uppercase mb-0">
                Meet Your{" "}
                <span className="section-8-mentor position-relative">
                  Mentors
                </span>
              </h1>
            </div>
            <div className="col-lg-8">
              <p className="mb-4">
                You’re in good hands—our mentors have been there, done that, and
                sent thousands of students to top universities worldwide.
              </p>
              <p className="mb-4">
                Our mentors are not just experienced—they’re passionate about
                helping students like you. They’ve helped 30,000+ students over
                23 years, and they’re still excited about every new success
                story. Now, they’re here to help YOU navigate your path with
                personalized advice and insider tips.
              </p>
              <p>
                <b>They’re ready to meet YOU!</b>
              </p>
              <div className="pt-3 pb-5 pt-md-5">
                <Link
                  activeClass="active"
                  to="cwcregistration-form"
                  offset={-120}
                  duration={300}
                  className="event-banner-btn"
                >
                  Reserve My Free Spot & Coffee Now!
                </Link>
              </div>
            </div>
            <div className="col-lg-4 text-center">
              <div>
                <img src={BhavinThakkar} alt="Bhavin Thakar" />
                <h3 className="m-0 text-white">Mr. Bhavin Thakar</h3>
                <p>Director</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cwc-event-section-9 pt-5 mb-0 pb-3 pb-md-5">
        <div className="container">
          <div className="row mb-4 mb-lg-5">
            <div className="col-8">
              <h1 className="text-uppercase mb-0">Social Proof?</h1>
            </div>
            <div className="col-4 text-end">
              <img
                src={RightAdvice}
                alt="Right Advice"
                className="right-advice"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-9">
              <p>
                No, we don’t have testimonials from this exact event (yet!) –
                because it's the <b>first of its kind!</b>
              </p>
              <p className="section-9-line">
                But over the past <b>23 years</b>, we’ve counseled, guided, and
                helped
                <b
                  style={{
                    background: "#E65272",
                    color: "#fff",
                    padding: "0 5px",
                    margin: "0 0 0 5px",
                  }}
                >
                  30000+ students
                </b>{" "}
                get enrolled in top universities in{" "}
                <b
                  style={{
                    background: "#E65272",
                    color: "#fff",
                    padding: "0 5px",
                    margin: "0 0 0 5px",
                  }}
                >
                  6+ countries!
                </b>
              </p>
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-10 offset-lg-1">
              <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                // slidesPerView={3} // Show 3 slides
                initialSlide={1}
                coverflowEffect={{
                  rotate: 50,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: true,
                }}
                pagination={true}
                navigation={true}
                modules={[EffectCoverflow, Pagination, Navigation]}
                className="mySwiper"
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
              >
                {testimonials.map((testimonial, index) => (
                  <SwiperSlide key={index}>
                    <div className="testimonial-slide">
                      <p>{testimonial.text}</p>
                      <h3>{testimonial.name}</h3>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
      <Link
        activeClass="active"
        to="cwcregistration-form"
        offset={-120}
        duration={300}
        className="cwc-event-footer-section"
      ></Link>
    </div>
  );
};

export default CWCEventPage;
