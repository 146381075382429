export const BASE_URL = "http://localhost:3000/";
export const LIVE_URL = "https://website.careerline.in/";

// LIVE_URL
export const BACKEND_BASE_URL = "https://webapi.careerline.org/api";
export const PORTAL_BACKEND_BASE_URL = "https://careerline.in/api";
export const REACT_APP_RAZORPAY_KEY_ID = "rzp_live_7gkPFL0smYK2OC";

//DEMO_URL
// export const BACKEND_BASE_URL = "https://websiteapi.careerline.in/api";
// export const PORTAL_BACKEND_BASE_URL = "https://api.careerline.in/api";
// export const REACT_APP_RAZORPAY_KEY_ID = "rzp_test_dzBL83csHJcglR";

export const STATUS_ARRAY = [
  {
    label: "Exam Not Given",
    value: 1,
  },
  {
    label: "Score Ready",
    value: 2,
  },
  {
    label: "Exam Given",
    value: 3,
  },
  {
    label: "Date Taken",
    value: 4,
  },
];

export const CLG_LIST = [
  {
    label: "DEPaul University",
    value: "DEPaul University",
  },
  {
    label: "Florida Atlantic University",
    value: "Florida Atlantic University",
  },
  {
    label: "James Madison University",
    value: "James Madison University",
  },
  {
    label: "University of Hartford",
    value: "University of Hartford",
  },
  {
    label: "California State University San Marcos",
    value: "California State University San Marcos",
  },
  {
    label: "Long Island University",
    value: "Long Island University",
  },
  {
    label: "Towson University",
    value: "Towson University",
  },
  {
    label: "University of Nebraska Omaha",
    value: "University of Nebraska Omaha",
  },
  {
    label: "Western Washington University",
    value: "Western Washington University",
  },
  {
    label: "Lipscomb University",
    value: "Lipscomb University",
  },
  {
    label: "Texas A&M University Corpus Christi",
    value: "Texas A&M University Corpus Christi",
  },
];
