export const appBarData = [
  {
    id: "home",
    label: "HOME",
    icon: "",
    link: "/",
    submenus: [],
  },
  {
    id: "aboutus",
    label: "About Us",
    icon: "",
    link: "/about/",
    submenus: [],
  },
  // {
  //   id: "ieltsonline",
  //   label: "Ielts Online",
  //   icon: "",
  //   link: "/ieltsonline",
  //   submenus: [],
  // },
  // {
  //   id: "ecounselling",
  //   label: "E-Counselling",
  //   icon: "",
  //   link: "#",
  //   submenus: [],
  // },
  {
    id: "coaching",
    label: "Coaching",
    icon: "",
    link: "/coaching",
    submenus: [
      {
        id: "ielts",
        label: "IELTS",
        link: "/coaching/ielts/",
        parentId: "coaching",
      },
      {
        id: "gre",
        label: "GRE",
        link: "/coaching/gre/",
        parentId: "coaching",
      },
      // {
      //   id: "toefl",
      //   label: "TOEFL",
      //   link: "/coaching/toefl/",
      //   parentId: "coaching",
      // },
      {
        id: "pte",
        label: "PTE",
        link: "/coaching/pte/",
        parentId: "coaching",
      },
      {
        id: "sat",
        label: "SAT",
        link: "/coaching/sat/",
        parentId: "coaching",
      },
    ],
  },
  {
    id: "studyabroad",
    label: "Study Abroad",
    icon: "",
    link: "/studyabroad/",
    submenus: [
      {
        id: "canada",
        label: "Canada",
        link: "/studyabroad/study-in-canada/",
        parentId: "/studyabroad",
      },
      {
        id: "usa",
        label: "USA",
        link: "/studyabroad/study-in-usa/",
        parentId: "/usa",
      },
      {
        id: "unitedkingdom",
        label: "United kingdom",
        link: "/studyabroad/study-in-unitedkingdom/",
        parentId: "/unitedkingdom",
      },
      {
        id: "australia",
        label: "Australia",
        link: "/studyabroad/study-in-australia/",
        parentId: "/australia",
      },
      {
        id: "newzealand",
        label: "New Zealand",
        link: "/studyabroad/study-in-newzealand/",
        parentId: "/newzealand",
      },
    ],
  },
  {
    id: "events",
    label: "Upcoming Events",
    icon: "",
    link: "",
    submenus: [
      // {
      //   id: "coffeewithcareearline",
      //   label: "Coffee With Careearline",
      //   // link: "events/coffeewithcareearline/",
      //   link: "events/coffeewithcareearline/website/",
      //   parentId: "/coffeewithcareearline",
      // },
      // {
      //   id: "usauniversityseminar",
      //   label: "USA Universities Seminar",
      //   link: "/events/usauniversityseminar/website/",
      //   parentId: "/usauniversityseminar",
      // },
      {
        id: "coffeewithcareerline",
        label: "Coffee With Careerline",
        link: "/events/coffeewithcareerline",
        parentId: "/coffeewithcareerline",
      },
      // {
      //   id: "megaseminar",
      //   label: "Mega Seminar",
      //   link: "/events/megaseminar/",
      //   parentId: "/megaseminar",
      // },
      {
        id: "worldeducationfair",
        label: "World Education Fair-Ahmedabad",
        link: "/events/worldeducationfair/website/",
        parentId: "/worldeducationfair",
      },
      {
        id: "worldeducationfair",
        label: "World Education Fair-Surat",
        link: "/events/worldeducationfair/surat/website/",
        parentId: "/worldeducationfair",
      },
      {
        id: "worldeducationfair",
        label: "World Education Fair-Anand",
        link: "/events/worldeducationfair/anand/website/",
        parentId: "/worldeducationfair",
      },
      {
        id: "ielts",
        label: "IELTS Mock Test",
        link: "/events/ieltsmocktest/",
        parentId: "/ieltsmocktest",
      },
      // {
      //   id: "coffeewithcareerline",
      //   label: "Coffee With Careerline 2",
      //   link: "/events/coffeewithcareerline2",
      //   parentId: "/coffeewithcareerline2",
      // },
      // {
      //   id: "worldeducationfairnew",
      //   label: "World Education Fair New",
      //   link: "/events/worldeducationfairnew/website/",
      //   parentId: "/worldeducationfairnew",
      // },
      // {
      //   id: "americaneducationexpo",
      //   label: "American Education Expo",
      //   link: "events/educationexpo/website/",
      //   parentId: "/americaneducationexpo",
      // },
      // {
      //   id: "americaneducationexpo",
      //   label: "Nikol Addmission Week",
      //   link: "events/admissionweek/nikol/website/",
      //   parentId: "/americaneducationexpo",
      // },
      // {
      //   id: "americaneducationexpo",
      //   label: "Manav Mandir Addmission Week",
      //   link: "events/admissionweek/manavmandir/website/",
      //   parentId: "/americaneducationexpo",
      // },
      // {
      //   id: "webinar",
      //   label: "Webinar",
      //   link: "events/webinar/website/",
      //   parentId: "webinar",
      // },
    ],
  },
  {
    id: "summercamp",
    label: "Summer Camp",
    icon: "",
    link: "/summercamp/",
    submenus: [],
  },
  {
    id: "blogs",
    label: "Blogs",
    icon: "",
    link: "/blogs/",
    submenus: [],
  },
  {
    id: "contactus",
    label: "Contact Us",
    icon: "",
    link: "/contactus/",
    submenus: [],
  },
];
