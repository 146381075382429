import { useFormik } from "formik";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import LoadingSpinner from "../../Components/Common/LoadingSpinner";
import { ErrorToast, SuccessToast } from "../../Components/Common/Toast";
import "react-phone-number-input/style.css";
import {
  BACKEND_BASE_URL,
  PORTAL_BACKEND_BASE_URL,
  REACT_APP_RAZORPAY_KEY_ID,
} from "../../common/constant";
import { parseQueryParams } from "../../common/commonHelpers";
const IELTSLandingPage2Form = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathName = location.pathname?.split("/")[1] || "";

  const source =
    (location.pathname?.split("/") && location.pathname?.split("/")[2]) || "";
  const { Campaign } = useParams();
  const searchParams = parseQueryParams(location.search);

  const [loading, setLoading] = useState(false);
  const [initialValues] = useState({
    FirstName: "",
    LastName: "",
    EmailID: "",
    MobileNo: null,
    Location: null,
  });

  const phoneRegExp = /^[0-9]{10,10}$/;

  const validationSchema = Yup.object().shape({
    FirstName: Yup.string().required("First Name is required"),
    LastName: Yup.string().required("Last Name is required"),
    MobileNo: Yup.string()
      .required("Mobile number is required")
      .matches(phoneRegExp, "Mobile number is not valid"),
    EmailID: Yup.string()
      .required("Email Address is required")
      .email("Invalid email"),
    Location: Yup.string().required("Location is required"),
  });

  const onSubmit = async (values) => {
    const payload = {
      ...values,
      MobileNo: values.MobileNo ? values.MobileNo.toString() : "",
      // FromPage: pathName || "",
      // OperationType: 1,
      // EventID: 23, //Demo
      EventID: 127, //Live
      Campaign: Campaign
        ? Campaign
        : searchParams?.utm_source
        ? searchParams?.utm_source
        : "website",
      UTMCampaign: searchParams?.utm_campaign ? searchParams?.utm_campaign : "",
      UTMMedium: searchParams?.utm_medium ? searchParams?.utm_medium : "",
      UTMContent: searchParams?.utm_content ? searchParams?.utm_content : "",
    };

    const dataLayerResponse = [
      { name: "your-first-name", value: values.FirstName },
      { name: "your-last-name", value: values.LastName },
      { name: "your-email", value: values.EmailID },
      { name: "tel-472", value: values.MobileNo },
      { name: "text-25", value: "ielts" },
    ];

    try {
      // setLoading(true);
      // const response = await fetch(`${BACKEND_BASE_URL}/Master/ManageInquiry`, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify(payload),
      // });
      setLoading(true);
      const response = await fetch(
        `${PORTAL_BACKEND_BASE_URL}/Events/AddCWCEvents`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const responseData = await response.json();
      console.log("responseData", responseData);
      if (responseData.Status !== 200) {
        ErrorToast(responseData?.Details);
      } else {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "lead_generate",
          formId: 1,
          dataLayerResponse,
        });

        const tempMainPayload = {
          StudentID: responseData?.Data?.StudentID,
          FirstName: values?.FirstName ? values?.FirstName : "",
          LastName: values?.LastName ? values?.LastName : "",
          EmailID: values?.EmailID ? values?.EmailID : "",
          MobileNo: values.MobileNo ? `${values.MobileNo}` : "",
          CourseName: "ielts",
          Amount: 49,
        };

        fetch(`${PORTAL_BACKEND_BASE_URL}/EventRegistration/TempIELTSInsert`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(tempMainPayload),
        });

        const orderResponse = await fetch(
          `${PORTAL_BACKEND_BASE_URL}/RazorpayGateway/CreateOrder`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              Amount: 49 * 100,
              Currency: "INR",
            }),
          }
        );

        const orderDetails = await orderResponse.json();
        const OrderData = orderDetails.Data;

        const options = {
          key: REACT_APP_RAZORPAY_KEY_ID,
          amount: OrderData.Amount,
          currency: OrderData.Currency,
          name: "CAREERLINE EDUCATION FOUNDATION",
          description: `transction_${responseData?.Data?.StudentID}`,
          order_id: OrderData.Orderid,
          handler: async function (response) {
            const paymentOrderDetails = await fetch(
              `${PORTAL_BACKEND_BASE_URL}/RazorpayGateway/verify`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  RazorpayOrderId: response.razorpay_order_id,
                  RazorpayPaymentId: response.razorpay_payment_id,
                  RazorpaySignature: response.razorpay_signature,
                  booking_no: responseData?.Data?.StudentID,
                }),
              }
            );
            const paymentOrderDetailsData = await paymentOrderDetails.json();
            if (paymentOrderDetailsData?.Status === 200) {
              navigate("/thankyou/");
              // SuccessToast(responseData?.Details);
            } else {
              ErrorToast(paymentOrderDetails?.Details);
              console.error(
                "Payment verification failed:",
                paymentOrderDetails.Details
              );
            }
          },
          theme: {
            color: "green",
          },
        };

        const rzp = new window.Razorpay(options);
        rzp.open();

        rzp.on("payment.failed", function (response) {
          ErrorToast(response.error.description);
          console.error("Payment failed", response);
        });

        // navigate("/thankyou/");
        // SuccessToast(responseData?.Details);
      }
    } catch (error) {
      ErrorToast(error?.message || "Network Error");
      console.error("An error occurred during the API call:", error);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  return (
    <form onSubmit={formik.handleSubmit} className="ielts2-form">
      {loading && <LoadingSpinner />}
      <div className="row">
        <div className="col-12 d-flex">
          <div
            className="card flex-fill border-0"
            style={{
              background: "transparent",
            }}
          >
            <div className="card-body row pt-0">
              <div className="col-6 p-0 pe-1">
                <div className="form-group row">
                  <label className="col-12 col-form-label">
                    First Name<code>*</code>
                  </label>
                  <div className="col-12">
                    <input
                      name="FirstName"
                      type="text"
                      id="FirstName"
                      placeholder="Enter First Name"
                      className={`form-control ${
                        formik.errors.FirstName &&
                        formik.touched.FirstName &&
                        "error-border"
                      }`}
                      maxLength="50"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.FirstName}
                    />
                  </div>
                </div>
              </div>
              <div className="col-6 p-0 ps-1">
                <div className="form-group row">
                  <label className="col-12 col-form-label">
                    Last Name<code>*</code>
                  </label>
                  <div className="col-12">
                    <input
                      name="LastName"
                      type="text"
                      id="LastName"
                      placeholder="Enter Last Name"
                      className={`form-control ${
                        formik.errors.LastName &&
                        formik.touched.LastName &&
                        "error-border"
                      }`}
                      maxLength="50"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.LastName}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 p-0">
                <div className="form-group row">
                  <label className="col-12 col-form-label">
                    Mobile No.<code>*</code>
                  </label>
                  <div className="col-12">
                    <input
                      name="MobileNo"
                      type="number"
                      id="MobileNo"
                      placeholder="Enter Mobile No"
                      className={`form-control ${
                        formik.errors.MobileNo &&
                        formik.touched.MobileNo &&
                        "error-border"
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.MobileNo}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 p-0">
                <div className="form-group row">
                  <label className="col-12 col-form-label">
                    Email ID<code>*</code>
                  </label>
                  <div className="col-12">
                    <input
                      name="EmailID"
                      type="text"
                      id="EmailID"
                      placeholder="Enter Email ID"
                      className={`form-control ${
                        formik.errors.EmailID &&
                        formik.touched.EmailID &&
                        "error-border"
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.EmailID}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 p-0">
                <div className="form-group row">
                  <label className="col-12 col-form-label">
                    Location<code>*</code>
                  </label>
                  <div className="col-12">
                    <select
                      id="Location"
                      name="Location"
                      className={`form-control ${
                        formik.errors.Location &&
                        formik.touched.Location &&
                        "error-border"
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Location}
                    >
                      <option value={null} label={"Select Your Location"} />
                      <option value={"Manav Mandir"} label={"Manav Mandir"} />
                      <option value={"Nikol"} label={"Nikol"} />
                      <option value={"Surat"} label={"Surat"} />
                      <option value={"Anand"} label={"Anand"} />
                    </select>
                  </div>
                </div>
              </div>
              <div className="text-center p-0 mt-3">
                <button
                  id="submit-btn"
                  className="submit-btn"
                  type="submit"
                  disabled={loading}
                >
                  {"Register Now & Pay"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default IELTSLandingPage2Form;
