import React from "react";
import "./IELTSLandingPage2Style.css";
import logo from "../../assets/image/logo.png";
import IELTSMOCKTEST from "../../assets/image/IELTS EXAM/ieltsmocktest.jpg";
import IELTSMOCKTEST2 from "../../assets/image/IELTS EXAM/ieltsmocktest2.jpeg";
import COURSECOMPARE from "../../assets/image/IELTS EXAM/coursecompare.jpg";
import FAQ1 from "../../assets/image/IELTS EXAM/ieltslandingpagefaq1.jpg";
import FAQ2 from "../../assets/image/IELTS EXAM/ieltslandingpagefaq2.jpg";
import FAQ from "../../assets/image/IELTS EXAM/ieltslandingpagefaqlogo.png";
import harshilboradphoto from "../../assets/image/IELTS EXAM/harshilboradphoto.png";
import ShrutiMehulkumarThakkar from "../../assets/image/IELTS EXAM/ShrutiMehulkumarThakkar.png";
import arpitphoto from "../../assets/image/IELTS EXAM/arpitphoto.png";
import NidhiPanchal from "../../assets/image/IELTS EXAM/NidhiPanchal.png";
import talhakashif from "../../assets/image/IELTS EXAM/talhakashif.png";
import VipraVekariyaPhoto from "../../assets/image/IELTS EXAM/VipraVekariyaPhoto.png";
import { MdCall } from "react-icons/md";
import IELTSLandingPage2Form from "../ielts2/IELTSLandingPage2Form";
import { ImCalendar } from "react-icons/im";
import { LuClock5 } from "react-icons/lu";
import { GrSend } from "react-icons/gr";
import { FaCircleCheck } from "react-icons/fa6";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";

const faqData = [
  {
    question: `is the mock test really free?`,
    answer: `Yes, there are absolutely no charges for attending this offline session`,
  },
  {
    question: `Where is the test conducted?`,
    answer: `All tests are conducted at our Careerline centers. Exact location details will be shared after registration.`,
  },
  {
    question: `Would you get results in 48 hours?`,
    answer: `Yes, we do give results in less than 48 hours.`,
  },
];

const testimonialData = [
  {
    id: 1,
    img: harshilboradphoto,
    name: "Harshil Borad",
    achivement:
      "London Metropolitan University, BSc (Hons) Computer Networking and Cyber Security United Kingdom (UK)",
    description: `recently got my Student visa to pursue a Bachelor of Scilice in the UK. A lot of efforts have been directed by the UK team to achieve it. From shortlisting universities to preparing documentation for university and visa applications. Also, I want to extend thanks to Vrutik sir, Roshni Mam, and Twinkle Mam for providing the perfect pathway for getting a visa. They are very friendly in nature. I would always recommend them to anyone studying in the UK`,
  },
  {
    id: 2,
    img: ShrutiMehulkumarThakkar,
    name: "Shruti Mehulkumar Thakkar",
    achivement:
      "Bachelor of Science in Computer Science, University of Central Florida, United States of America (USA), Intake: Spring 2024",
    description: `Careerline is the one-stop for all the requirements. From testing requirements to counseling, they provide seamless support in every step. They have the perfeu support for IELTS and SAT scores for Improving overall English skills and gaining boundless knowledge. The USA team is also very supportive, Helpful to schve any complications, the USA counselors make the University process very smooth. I undoubtedly recommend Careerline for every student`,
  },
  {
    id: 3,
    img: arpitphoto,
    name: "Arpit Trivedi",
    achivement: "Seneca College, Financial Planning Canada",
    description: `I had a fantastic experience with Careerline Education Foundation! The teaching methods of lyer Sir as well as all the faculty members were exceptional ant helped me achieve an impressive 7.5 overall band score, with a 7 in writing, Further, I am truly grateful to Hetal Ma'am. Simmy Ma ain, and the ret Team for then relentless support. Thanks to their guidance and expertise, my visa got approved in just 14 days. I highly recommend Carverline Educa Foundation for anyone seeking top-notch IELTS preparation and excellent visa assistance`,
  },
  {
    id: 4,
    img: NidhiPanchal,
    name: "Nidhi Panchal",
    achivement: "University Canada West, MBA, Canada",
    description: `My experience with Careerline was a bit special as I got connected through the seminar in December 2022. A special mention to Ami Ma'am for her prompt responses and for helping me out with the whole process so kindly. I got my visa approved for Jan 2024 intake in just 10 days and the major credit goes to Ami Ma'am and Aayush Sir. I am thankful to the whole team for all the assistance.`,
  },
  {
    id: 5,
    img: talhakashif,
    name: "Talha Kashif",
    achivement: "Canadore College Entrepreneurship Management, Canada",
    description: `Had a great experience with Careerline for my college application and visa processing. They have knowledgeable and expert staff who guided me through the entire process. I would special like to acknowledge the help I received from my case manager Mr. Bonit, he efficiently handled my case considerang Some very tricky situations that I faced, I would wholeheartedly recommend Careerline for admission to colleges abroad.`,
  },
  {
    id: 6,
    img: VipraVekariyaPhoto,
    name: "Vipra Laljibhai Vekariya",
    achivement:
      "London Metropolitan University, MSc International Business Management, UK",
    description: `I got my UK visa... and very thankful to the Surat Careerline team for getting my UK visa. Kextremely recommend Carrierline because they have given me the best advice and they are very helpful and too supportive and kind. I am especially thankful to the Surat Careerline team.`,
  },
];

const IELTSLandingPage2 = () => {
  return (
    <>
      <div className="ielts2-section-1 m-0 p-0">
        <div className="container d-flex align-items-center justify-content-between p-1">
          <div className="d-flex p-2 pt-1 pb-1" style={{ background: "white" }}>
            <img src={logo} alt="Logo" className="logo" />
          </div>
          <a className="callus-btn" href="tel:%20+916358931838">
            <MdCall className="call-icon" /> Call Us
          </a>
        </div>
      </div>
      <div className="ielts2-section-2 m-0 p-0">
        <div className="container">
          <div className="row pt-5 pb-5">
            <div className="col-lg-6 mt-4 mt-lg-0" id="ielts-form">
              <h1 className="text-start">
                IELTS Score in Just 48 Hours –{" "}
                <span style={{ color: "#FEED00" }}>100% Free!</span>
              </h1>
              <p className="m-0">
                Take the mock test, get expert feedback, and see where you stand
                in no time!
              </p>
              <IELTSLandingPage2Form />
            </div>
            <div className="col-lg-6 d-flex justify-content-end align-items-start">
              <img src={IELTSMOCKTEST} alt="mocktest" className="mocktest" />
            </div>
          </div>
        </div>
      </div>
      <div className="ielts2-section-3 m-0 p-0">
        <div className="container">
          <div className="row pt-2 pb-2">
            <div
              className="col-md-6 col-lg-4 pt-3 pb-3 text-center text-lg-start"
              id="ielts-form"
            >
              <ImCalendar style={{ fontSize: 21 }} />
              &nbsp;&nbsp; 22 February 2025
            </div>
            <div
              className="col-md-6 col-lg-4 pt-3 pb-3 text-center"
              id="ielts-form"
            >
              <LuClock5 style={{ fontSize: 21 }} />
              &nbsp;&nbsp; 10:00 Am to 1:00 PM & 4:30 to 7:30 PM
            </div>
            <div
              className="col-md-6 col-lg-4 pt-3 pb-3 text-center text-lg-end"
              id="ielts-form"
            >
              <GrSend style={{ fontSize: 21 }} />
              &nbsp;&nbsp; Ahmedabad, Surat, Anand
            </div>
          </div>
        </div>
      </div>
      <div className="ielts2-section-4 m-0 p-0 pt-4 pb-4">
        <div className="container">
          <div className="row pt-2 pb-2">
            <div className="col-md-8">
              <h1>Why Attend IELTS</h1>
              <table>
                <tr>
                  <td>
                    <FaCircleCheck className="check-icon" />
                  </td>
                  <td>
                    <p>Paper Checking within 48 hours</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <FaCircleCheck className="check-icon" />
                  </td>
                  <td>
                    <p>Real IELTS Test Simulation​</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <FaCircleCheck className="check-icon" />
                  </td>
                  <td>
                    <p>Feedback sessions with certified trainers</p>
                  </td>
                </tr>
              </table>
            </div>
            <div className="col-md-4 text-center text-md-end mt-4">
              <img src={IELTSMOCKTEST2} alt="mocktest" className="mocktest" />
            </div>
          </div>
        </div>
      </div>
      <div className="ielts2-section-5 m-0 p-0">
        <div className="container">
          <div className="row pt-2 pb-2">
            <div className="col-12 text-center ">
              <img
                src={COURSECOMPARE}
                alt="coursecompare"
                className="coursecompare"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="ielts2-section-6 m-0 p-0 pt-4 pb-4">
        <div className="container">
          <div className="row pt-2 pb-2">
            <div className="col-12 text-start ">
              <h1 className="text-start m-0">
                45,000+{" "}
                <span style={{ color: "#FEED00" }}>Success Stories</span>
              </h1>
              <p>From Dreamers to Achievers</p>
            </div>
            <div className="col-12 text-start ">
              <Swiper
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                loop={true}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination, Autoplay]}
                className="mySwiper"
              >
                {testimonialData?.map((testimonial) => (
                  <SwiperSlide>
                    <p className="testimonial-para">
                      {testimonial?.description}
                    </p>
                    <div>
                      <img
                        src={testimonial?.img}
                        alt="avatar"
                        className="testimonial-avatar"
                      />
                    </div>
                    <h5 className="text-center mt-1">{testimonial?.name}</h5>
                    <p className="achivement m-0">{testimonial?.achivement}</p>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
      <div className="ielts2-section-7 m-0 p-0 pt-4">
        <div className="container">
          <div className="row pt-2 pb-2">
            <div className="col-md-6 text-center faq-card ">
              <img src={FAQ1} alt="expert" />
              <div className="text-center text-md-start ps-2 pe-2 mt-3 mt-md-0">
                <h4>Expert Feedback from Certified Trainers</h4>
                <p>
                  After completing the test, receive detailed feedback from
                  experienced IELTS trainers.
                </p>
                <p>
                  <a className="callus-btn" href="tel:%20+916358931838">
                    Call us
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-6 text-center faq-card ">
              <img src={FAQ2} alt="expert" />
              <div className="text-center text-md-start ps-2 pe-2 mt-3 mt-md-0">
                <h4>Immediate 1:1 Guidance</h4>
                <p>
                  Sit down with our experts after the mock test for a
                  personalized session.
                </p>
                <p>
                  <a className="callus-btn" href="tel:%20+916358931838">
                    Call us
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ielts2-section-8 m-0 p-0">
        <div className="container pt-5 pb-5">
          <div className="row d-none d-md-block">
            <div className="col-12">
              <h1 className="text-start">FAQs</h1>
            </div>
          </div>
          <div className="row testimonial-sec">
            <div className="col-md-8 faqs-list order-1 order-md-0">
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                {faqData.map((item, index) => (
                  <div className="accordion-item" key={index}>
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className={`accordion-button ${
                          index === 0 ? "" : "collapsed"
                        }`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-headingOne${index}`}
                        aria-expanded={index === 0 ? "true" : "false"}
                        aria-controls="flush-collapseOne"
                      >
                        {item?.question}
                      </button>
                    </h2>
                    <div
                      id={`flush-headingOne${index}`}
                      className={`accordion-collapse collapse  ${
                        index === 0 ? "show" : ""
                      }`}
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">{item?.answer}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-4">
              <img src={FAQ} alt="faq" className="faq" />
            </div>
          </div>
        </div>
      </div>
      <div className="ielts2-section-9 m-0 p-0">
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-9 mb-3 mb-md-0">
              <div
                style={{
                  display: "inline-block",
                  background: "#fff",
                  padding: "3px 9px",
                  borderRadius: "5px",
                }}
              >
                <img src={logo} alt="logo" className="logo" />
              </div>
              <p className="mt-2">
                A truly global higher education partner for learners and
                education institutions. Supports learners at every point in
                their global education journey with reliable services, products,
                and solutions
              </p>
              <div className="footer-top-para-right">
                <p className="pb-1">
                  <i className="bi bi-envelope" />
                  <a href="mailto:admin@careerline.org">admin@careerline.org</a>
                </p>
                <i className="bi bi-telephone-plus" />
                <a
                  href="tel:6358931838"
                  // contentEditable="false"
                  style={{ cursor: "pointer" }}
                >
                  +91 6358 931 838,
                </a>
                <a
                  href="tel:6358989873"
                  // contentEditable="false"
                  style={{ cursor: "pointer" }}
                >
                  +91 6358 989 873
                </a>
              </div>
              <div className="footer-socialmedia mt-2">
                <a
                  href="https://www.facebook.com/CareerlineEF/"
                  target="_blank"
                  style={{ background: "#3B5998" }}
                >
                  <i className="bi bi-facebook" />
                </a>
                <a
                  href="https://twitter.com/Careerlineef"
                  target="_blank"
                  style={{ background: "#1DA1F2" }}
                >
                  <i className="bi bi-twitter" />
                </a>
                <a
                  href="https://www.linkedin.com/company/careerlineef/"
                  target="_blank"
                  style={{ background: "#0077B5" }}
                >
                  <i className="bi bi-linkedin" />
                </a>
                <a
                  href="https://www.youtube.com/c/CareerlineEducationFoundation/"
                  target="_blank"
                  style={{ background: "#CD201F" }}
                >
                  <i className="bi bi-youtube" />
                </a>
                <a
                  href="https://www.instagram.com/careerlineef/"
                  target="_blank"
                  style={{
                    background: "linear-gradient(45deg, #902af0, #ed1d1d)",
                  }}
                >
                  <i className="bi bi-instagram" />
                </a>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3 ">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12349.140012101447!2d72.53275668773627!3d23.045011391639978!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e848ac8a7faf3%3A0x605d0c100abfcc1a!2sCareerline%20Education%20Foundation!5e0!3m2!1sen!2sin!4v1724236290085!5m2!1sen!2sin"
                width={"100%"}
                height={"100%"}
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IELTSLandingPage2;
